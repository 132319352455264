// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { Parallax } from "react-parallax"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allContentfulHomePage
  allContentfulGallery
}

const ParallaxButton = styled.button`
  font-family: Montserrat, sans-serif;
  font-size: 26px;
  border: 2px solid white;
  background: transparent;
  width: 250px;
  padding: 16px 32px;
  color: white;
  cursor: pointer;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    border: 2px solid white;
    background: white;
    color: black;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  }
`

const ParallaxText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 80%;

  p {
    font-size: 18px;
    margin: 0;
  }

  h1 {
    font-weight: normal;
  }

  @media (min-height: 800px) {
    p {
      font-size: 22px;
    }
  }

  @media (min-width: 1080px) {
    width: 60%;

    h1 {
      font-size: 50px;
    }

    p {
      font-size: 28px;
    }
  }
`

const StyledParallax = styled(Parallax)`
  @media (max-width: 500px) {
    img {
      height: 160% !important;
      object-fit: none !important;
    }
  }
`

const ParallaxContainer = styled.div`
  height: calc(100vh - 80px);
  /* @media (min-width: 768px) {
    height: 70vh;
  } */
`

const StyledSection = styled.section`
  p {
    margin-bottom: 20px;
  }
`

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const homeImages = data.allContentfulHomePage.edges[0].node.parallaxImages

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Homepage" />

      {homeImages.map(parallaxBlock => (
        <StyledParallax
          key={parallaxBlock.id}
          bgImage={parallaxBlock.parallaxBackgroundImage.fluid.src}
          bgImageAlt="Homepage image"
          strength={500}
          bgImageStyle={{ objectFit: "cover" }}
        >
          <ParallaxContainer
            style={{
              backgroundImage:
                "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
            }}
          >
            <ParallaxText data-sal="fade" data-sal-easing="ease">
              {parallaxBlock.imageTitle !== null && (
                <h1>{parallaxBlock.imageTitle}</h1>
              )}

              <StyledSection>
                {parallaxBlock.imageText !== null &&
                  documentToReactComponents(parallaxBlock.imageText.json)}
              </StyledSection>

              {parallaxBlock.button && (
                <Link
                  to={parallaxBlock.buttonLink}
                  style={{ boxShadow: `none` }}
                >
                  <ParallaxButton>{parallaxBlock.buttonText}</ParallaxButton>
                </Link>
              )}
            </ParallaxText>
          </ParallaxContainer>
        </StyledParallax>
      ))}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHomePage {
      edges {
        node {
          title
          parallaxImages {
            id
            imageTitle
            button
            buttonLink
            buttonText
            parallaxBackgroundImage {
              fluid(quality: 70, maxWidth: 1920) {
                ...GatsbyContentfulFluid
              }
            }
            imageText {
              json
            }
          }
        }
      }
    }
  }
`
